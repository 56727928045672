import * as React from 'react';
import { Header, Footer } from '@/components/shared';
import { LayoutProps } from '@/interfaces/common';

export default function MainLayout({ children }: LayoutProps) {
  return (
    <>
      <Header />
      <div>{children}</div>
      <Footer />
    </>
  );
}
